import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
    state: {
        locale: "en-US",
        count: 0,
        scanlines: true,
        fontScale: 1,
        colorScale: 0,
        font: 0,
        desktop: true,
        currentPage: 1,
        activeGame: "default-game",
        musicPlayer: false,
        acquiredAchievements: {},
        achievementsOn: false
    },
    mutations: {
        increment(state) {
            state.count++
        },
        setFontScale(state, scale) {
            state.fontScale = scale;
        },
        setScanlines(state, scanlinesOn) {
            state.scanlines = scanlinesOn;
        },
        setColorScale(state, color)
        {
            state.colorScale = color;
        },
        setFont(state, selection) {
            state.font = selection;
        },
        setIsDesktop(state, isDesktop)
        {
            state.desktop = isDesktop;
        },
        setCurrentPage(state, page)
        {
            state.currentPage = page;
        },
        setActiveGame(state, activeGame)
        {
            state.activeGame = activeGame;
        },
        setMusicPlayer(state, musicPlayer) {
            state.musicPlayer = musicPlayer;
        },
        setAcquiredAchievements(state, acAch) {
            state.acquiredAchievements = acAch;
        },
        setAchievementsOn(state, achOn) {
            state.achievementsOn = achOn;
        }
    },
    getters:
    {
        getCount(state) { return state.count; },
        getScanlines(state) { return state.scanlines; },
        getColorScale(state) { return state.colorScale; },
        getFontScale(state) { return state.fontScale; },
        getFont(state) { return state.font; },
        getLocale(state) { return state.locale; },
        getDesktop(state) { return state.desktop; },
        getCurrentPage(state) { return state.currentPage; },
        getActiveGame(state) { return state.activeGame; },
        getMusicPlayer(state) { return state.musicPlayer; },
        getAcquiredAchievements(state) { return state.acquiredAchievements; },
        getAchievementsOn(state) { return state.achievementsOn; }
    }
})

export default store;