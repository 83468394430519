<template>
    <div class="projects-container">
        <div class="projects-content">
            <span class="projects-header" v-html="translate('projects-button')" />
            <span class="projects-subheader" v-html="translate('projects-subheader')" />
            <span class="projects-subheader" v-html="translate('projects-hint')" />
            <div class="projects-category" v-for="(group, g) in projectsData['project-groups']" :key="'group'+g">
                <span class="project-group" v-html="group.category" tabindex="0" role="button" @click="toggleActiveGroup(g)" v-on:keydown.enter="toggleActiveGroup(g)" :id="'group'+g" />
                <div class="project-list" v-if="activeGroup === g">
                    <div class="project-entry" v-for="(project, p) in group.projects" :key="'group'+g+'project'+p">
                        <a class="project-title" v-if="project.link && project.link !== ''" v-html="project.name" :href="project.link" target="_blank" />
                        <span class="project-title" v-else v-html="project.name" />
                        <span class="role" v-html="project.role" />
                        <div class="skills-list">
                            <span class="skills-title" v-html="translate('skills-used')" />
                            <span class="skill" v-for="(skill, s) in project.skills" v-html="skill" :key="'group'+g+'project'+p+'skill'+s" />
                        </div>
                        <span class="summary" v-html="project.description" />
                        <div class="description">
                            <div class="point" v-for="(point, po) in project['project-points']" :key="'group'+g+'project'+p+'point'+po">
                                <div class="bullet" />
                                <span class="text" v-html="point" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {
                projectsData: {},
                activeGroup: -1
            };
        },
        mounted: async function () {
            await fetch('./assets/data/projects.json').then((response) => { return response.json(); }).then((data) => { this.projectsData = data; });
        },
        methods: {
            toggleActiveGroup: function (group)
            {
                if (group === this.activeGroup) this.activeGroup = -1;
                else this.activeGroup = group;
            }
        },
        unmounted: function ()
        {
        }
    }
</script>
<style scoped lang="scss">
    .projects-container {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;

        .projects-content {
            position: relative;
            width: fit-content;
            height: fit-content;
            padding: 8px 10%;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            text-align: left;
            font-family: var(--body-font);
            color: var(--font-color);

            .projects-category {
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
                margin-bottom: 8px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .project-group {
                position: relative;
                font-family: var(--header-font);
                font-size: font(24);
                font-weight: 700;
                text-transform: uppercase;
                pointer-events: all;
                text-shadow: 2px 2px 2px var(--accent-color);

                &:active, &:hover {
                    color: var(--header-bg);
                }

                &:active {
                    text-shadow: 1px 1px 1px var(--accent-color);
                }
            }

            .projects-header {
                position: relative;
                font-family: var(--header-font);
                font-size: font(24);
                font-weight: 700;
                text-transform: uppercase;
            }

            .projects-subheader {
                position: relative;
                font-family: var(--header-font);
                font-size: font(16);
                font-style: italic;
            }

            .project-list {
                margin-left: 16px;
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;

                a {
                    color: var(--font-color);
                    text-decoration: none;

                    &:active, &:hover {
                        color: var(--header-bg);
                    }
                }
            }

            .project-entry {
                width: fit-content;
                height: fit-content;
                display:flex;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
                margin: 8px 0;
                font-size: font(16);
                border-bottom: 2px solid var(--header-bg);

                &:first-of-type {
                    margin-top: 0;
                }

                &:last-of-type {
                    margin-bottom: 0;
                    border-bottom: none;
                }

                .project-title {
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    font-family: var(--header-font);
                    font-weight: 700;
                }

                .skills-list {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-content: center;
                    align-items: center;
                    width: fit-content;
                    height: fit-content;
                    flex-wrap: wrap;
                    margin-bottom: 8px;

                    .skills-title {
                        position: relative;
                    }

                    .skill {
                        font-style: italic;
                        margin: 0 16px;

                        &:first-of-type {
                            margin-left: 8px;
                        }
                    }
                }

                .role {
                    position: relative;
                    font-family: var(--header-font);
                    font-style: italic;
                }

                .summary {
                    position: relative;
                    margin-bottom: 8px;
                }

                .description {
                    position: relative;
                    margin-left: 16px;
                    width: fit-content;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-items: flex-start;
                    align-content: flex-start;

                    .point {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        justify-items: flex-start;
                        justify-content: flex-start;
                        align-content: center;
                        align-items: center;
                        width: fit-content;
                        height: fit-content;
                        margin-bottom: 8px;
                        border-bottom: 2px dashed var(--header-bg);

                        &:last-of-type {
                            margin-bottom: 0;
                            border-bottom: none;
                        }

                        .bullet {
                            box-sizing: border-box;
                            width: font(16);
                            height: font(16);
                            border: solid var(--accent-color) font(8);
                            border-top: solid transparent font(8);
                            filter: drop-shadow(2px 2px 2px var(--font-color));
                            position: relative;
                            margin-right: 8px;
                            border-radius: 50%;
                            animation: rotate-bullet linear infinite 256s;
                            animation-direction: reverse;
                        }

                        .text {
                            position: relative;
                        }
                    }
                }
            }
        }

        @keyframes rotate-bullet {
            0% {
                transform: rotate(0);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
</style>