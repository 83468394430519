<template>
    <div class="app-container">
        <div class="header">
            <div class="header-option" role="button" tabindex="0" id="about-tab" :class="{'current': currentPage === 1}" @click="changePage(1)" v-on:keydown.enter="changePage(1)">
                <span v-html="translate('home-button')" />
            </div>
            <div class="header-option" role="button" tabindex="0" id="resume-tab" :class="{'current': currentPage === 2}" @click="changePage(2)" v-on:keydown.enter="changePage(2)">
                <span v-html="translate('resume-button')" />
            </div>
            <div class="header-option" role="button" tabindex="0" id="projects-tab" :class="{'current': currentPage === 3}" @click="changePage(3)" v-on:keydown.enter="changePage(3)">
                <span v-html="translate('projects-button')" />
            </div>
            <div class="header-option" role="button" tabindex="0" id="skills-tab" :class="{'current': currentPage === 4}" @click="changePage(4)" v-on:keydown.enter="changePage(4)">
                <span v-html="translate('skills-button')" />
            </div>
            <div class="header-option" role="button" tabindex="0" id="org-tab" :class="{'current': currentPage === 5}" @click="changePage(5)" v-on:keydown.enter="changePage(5)">
                <span v-html="translate('organizations-button')" />
            </div>
            <div class="header-option" role="button" tabindex="0" :class="{'current': currentPage === 7}" @click="changePage(7)" v-if="achievements" v-on:keydown.enter="changePage(7)">
                <span v-html="translate('achievements-button')"/>
            </div>
            <div class="header-option" role="button" tabindex="0" id="extras-tab" :class="{'current': currentPage === 9}" @click="changePage(9)" v-on:keydown.enter="changePage(9)">
                <span v-html="translate('extras-button')" />
            </div>
            <div class="header-option" role="button" tabindex="0" id="options-tab" :class="{'current': currentPage === 10}" @click="changePage(10)" v-on:keydown.enter="changePage(10)">
                <span v-html="translate('options-button')" />
            </div>
        </div>
        <div class="app-content">
            <div class="app-view-window">
                <div class="app-panel">
                    <TransitionerObject name="fade-and-blur-normal">
                        <HomePage v-if="displayedPage === 1" />
                    </TransitionerObject>
                    <TransitionerObject name="fade-and-blur-normal">
                        <ResumePage v-if="displayedPage === 2" />
                    </TransitionerObject>
                    <TransitionerObject name="fade-and-blur-normal">
                        <ProjectsPage v-if="displayedPage === 3" />
                    </TransitionerObject>
                    <TransitionerObject name="fade-and-blur-normal">
                        <EducationSkillsPage v-if="displayedPage === 4" />
                    </TransitionerObject>
                    <TransitionerObject name="fade-and-blur-normal">
                        <OrganizationsPage v-if="displayedPage === 5" />
                    </TransitionerObject>
                    <TransitionerObject name="fade-and-blur-normal">
                        <TextGame v-if="displayedPage === 6" />
                    </TransitionerObject>
                    <TransitionerObject name="fade-and-blur-normal">
                        <CheevosPage v-if="displayedPage === 7"/>
                    </TransitionerObject>
                    <TransitionerObject name="fade-and-blur-normal">
                        <ExtrasMenu v-if="displayedPage === 9" :change-page-callback="changePage" />
                    </TransitionerObject>
                    <TransitionerObject name="fade-and-blur-normal">
                        <OptionsMenu v-if="displayedPage===10" />
                    </TransitionerObject>
                </div>
            </div>
            <div class="box-overlay" v-if="scanlines" />
        </div>
        <div class="music-player-spacer" v-if="musicPlayer" />
        <TransitionerObject name="rise-normal">
            <MusicPlayer v-if="musicPlayer" />
        </TransitionerObject>
        <!--<CheevosOverlay id="cheevos"/>-->
        <div class="scrolling-effect" v-if="scanlines">
            <div class="scroll-line" />
            <div class="scroll-line" />
            <div class="scroll-line" />
        </div>
        <div class="scrolling-effect" style="animation-delay: 32s;" v-if="scanlines">
            <div class="scroll-line" />
            <div class="scroll-line" />
            <div class="scroll-line" />
        </div>
        <div class="scrolling-effect" style="animation-delay: 64s;" v-if="scanlines">
            <div class="scroll-line" />
            <div class="scroll-line" />
            <div class="scroll-line" />
        </div>
        <div class="scrolling-effect" style="animation-delay: 96s;" v-if="scanlines">
            <div class="scroll-line" />
            <div class="scroll-line" />
            <div class="scroll-line" />
        </div>
        <ScanlineEffect v-if="scanlines" />
    </div>
</template>

<script>
    import HomePage from './components/HomePage.vue';
    import ResumePage from './components/ResumePage.vue';
    import ProjectsPage from './components/ProjectsPage.vue';
    import EducationSkillsPage from './components/EducationSkillsPage.vue';
    import OrganizationsPage from './components/OrganizationsPage.vue';
	import ScanlineEffect from './components/ScanlineEffect.vue';
    import OptionsMenu from './components/OptionsMenu.vue';
    import ExtrasMenu from './components/ExtrasMenu.vue';
    import TextGame from './components/TextGame.vue';
    import TransitionerObject from './components/TransitionerObject.vue';
    import MusicPlayer from './components/MusicPlayer.vue';
    //import CheevosOverlay from './components/CheevosOverlay.vue';
    import CheevosPage from './components/CheevosPage.vue';
    import { mapGetters } from 'vuex';

	export default {
		name: 'App',
		components: {
            HomePage,
            ResumePage,
            ProjectsPage,
            EducationSkillsPage,
            OrganizationsPage,
			ScanlineEffect,
            OptionsMenu,
            ExtrasMenu,
            TextGame,
            TransitionerObject,
            MusicPlayer,
            CheevosPage
		},
		data: function () {
			return {
				displayedPage: 0,
                pageTimeout: null,
			};
		},
		methods:
		{
			changePage: function (page, overwriteDisplay = "") {
				if (this.currentPage === page || this.pageTimeout) return;
				this.displayedPage = 0;
                this.$store.commit("setCurrentPage", page);
				if (this.pageTimeout) clearTimeout(this.pageTimeout);
				this.pageTimeout = setTimeout(function () {
                    this.displayedPage = page;
                    let display = "about";
                    switch (page)
                    {
                        default:
                            break;
                        case 2:
                            display = "resume";
                            break;
                        case 3:
                            display = "projects";
                            break;
                        case 4:
                            display = "education-and-skills";
                            break;
                        case 5:
                            display = "organizations-and-affiliations";
                            break;
                        case 6:
                            display = (overwriteDisplay === "") ? "game" : overwriteDisplay;
                            break;
                        case 7:
                            display = (overwriteDisplay === "") ? "achievements" : overwriteDisplay;
                            break;
                        case 9:
                            display = "extras";
                            break;
                        case 10:
                            display = "options";
                            break;
                    }
                    window.history.replaceState(null, "", "?page=" + display);
					this.pageTimeout = null;
				}.bind(this), 500);
            }
		},
		computed:
		{
			...mapGetters({
                scanlines: 'getScanlines',
                currentPage: 'getCurrentPage',
                musicPlayer: 'getMusicPlayer',
                achievements: 'getAchievementsOn'
			})
        },
        mounted: async function ()
        {
            this.displayedPage=this.currentPage;
            setTimeout(function () {
                if (Object.keys(this.achievements).length > 0) this.dispatchAchievement("welcome-back");
                let today = new Date();
                if (today.getMonth() === 1 && today.getDate() === 16) this.dispatchAchievement("birthday");
                if (today.getMonth() === 9 && today.getDate() === 31) this.dispatchAchievement("boo");
            }.bind(this), 500);

            setTimeout(function () { this.dispatchAchievement("power-hour"); }.bind(this), 60*60*1000);
        }
}
</script>

<style lang="scss">
    $normal-transition-weight: 0.49s;
    $light-transition-weight: 0.24s;
    $heavy-transition-weight: 0.99s;
    .app-container {
        overflow-wrap: break-word;
        overflow-y: hidden;

        * {
            overflow-wrap: break-word;
        }

        font-family: var(--body-font);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;

        .header {
            position: relative;
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            justify-items: center;
            justify-content: center;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
            background-color: var(--header-bg);

            .header-option {
                position: relative;
                width: fit-content;
                height: fit-content;
                padding: 16px 24px;
                flex-grow: 1;
                background-color: var(--header-bg);
                pointer-events: visible;
                display: flex;
                flex-direction: row;
                justify-items: center;
                justify-content: center;
                align-items: center;
                align-content: center;
                border-left: 1px solid var(--font-color);
                border-right: 1px solid var(--font-color);

                span {
                    font-family: var(--header-font);
                    pointer-events: none;
                    position: relative;
                    font-size: font(16);
                    color: var(--font-color);
                    font-weight: 700;
                    text-shadow: 1px 1px var(--content-bg);
                    text-transform: uppercase;
                    text-align: center;
                }

                &:hover, &.current {
                    background-color: var(--accent-color);
                }

                &:active {
                    background-color: var(--content-bg);
                    box-shadow: inset 1px 1px 4px var(--font-color), inset -1px -1px 4px var(--font-color);
                }
            }
        }

        .app-content {
            position: relative;
            width: 100%;
            height: auto;
            flex-grow: 1;
            background-color: var(--content-bg);

            .app-view-window {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
                overflow-y: scroll;
                pointer-events: visible;

                .app-panel {
                    position: relative;
                    width: 100%;
                    min-height: 100%;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-items: flex-start;
                    align-content: flex-start;
                }
            }

            .box-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                box-shadow: inset 4px 4px 8px var(--font-color), inset -4px -4px 8px var(--font-color);
                pointer-events: none;
                animation: box-pulse infinite ease-in-out 16s;
            }
        }

        .music-player-spacer {
            animation: grow-player linear $normal-transition-weight;
            animation-iteration-count: 1;
            width: 100%;
            height: 96px;
        }

        @keyframes grow-player {
            0% {
                height: 0;
            }

            100% {
                height: 96px;
            }
        }

        .scrolling-effect {
            position: absolute;
            left: 0;
            top: -50%;
            width: 100%;
            height: fit-content;
            filter: blur(16px);
            opacity: 0.75;
            display: flex;
            flex-direction: column-reverse;
            justify-items: flex-end;
            justify-content: flex-end;
            align-items: flex-start;
            align-content: flex-start;
            animation: line-scroll linear 128s infinite;
            pointer-events: none;

            * {
                pointer-events: none;
            }

            .scroll-line {
                position: relative;
                width: 100%;
                background-color: var(--header-bg);
                height: 8px;

                &:first-of-type {
                    background-color: var(--font-color);
                }

                &:last-of-type {
                    background-color: var(--accent-color);
                }
            }
        }

        @keyframes box-pulse {
            0% {
                box-shadow: inset 4px 4px 10px var(--font-color), inset -4px -4px 10px var(--font-color);
            }

            50% {
                box-shadow: inset 8px 8px 8px var(--font-color), inset -8px -8px 8px var(--font-color);
            }

            100% {
                box-shadow: inset 4px 4px 10px var(--font-color), inset -4px -4px 10px var(--font-color);
            }
        }

        @keyframes line-scroll {
            0% {
                top: -50%;
            }

            100% {
                top: 150%;
            }
        }
    }
</style>
