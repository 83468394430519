<template>
    <div class="resume-container">
        <div class="resume-content">
            <span class="resume-title" v-html="translate('resume-button')"/>
            <div class="resume-entry" v-for="(job, index) in resumeData.jobs" :key="index">
                <div class="employer-section">
                    <span class="employer" v-html="job.employer"/>
                </div>
                <span class="position" v-html="job.position"/>
                <div class="dates">
                    <span class="date" v-html="job['start-date']"/>
                    <span style="margin: 0 4px;"> -> </span>
                    <span class="date" v-html="job['end-date']"/>
                </div>
                <span class="summary" v-html="job.summary"/>
                <div class="description">
                    <div class="point" v-for="(point, index) in job['description-points']" :key="index">
                        <div class="bullet"/>
                        <span class="text" v-html="point"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function () {
            return { resumeData: {} };
        },
        mounted: async function () {
            await fetch('./assets/data/resume.json').then((response) => { return response.json(); }).then((data) => { this.resumeData = data; });
        }
    }
</script>
<style scoped lang="scss">
    .resume-container {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: center;
        align-content: center;

        .resume-content {
            position: relative;
            width: fit-content;
            height: fit-content;
            padding: 8px 10%;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            text-align: left;
            font-family: var(--body-font);
            color: var(--font-color);

            .resume-title {
                position: relative;
                font-family: var(--header-font);
                font-size: font(32);
                font-weight: 700;
                text-transform: uppercase;
            }

            .resume-entry {
                width: fit-content;
                height: fit-content;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
                margin: 8px 0;
                font-size: font(16);
                border-bottom: 2px solid var(--header-bg);

                &:first-of-type {
                    margin-top: 0;
                }

                &:last-of-type {
                    margin-bottom: 0;
                    border-bottom: none;
                }

                .employer-section {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-content: flex-end;
                    align-items: flex-end;
                    width: fit-content;
                    height: fit-content;
                    font-size: font(24);
                    font-family: var(--header-font);

                    .employer {
                        position: relative;
                        font-weight: 700;
                    }

                    .location {
                        position: relative;
                    }
                }

                .position {
                    position: relative;
                    font-family: var(--header-font);
                    font-style: italic;
                }

                .dates {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-content: center;
                    align-items: center;
                    font-size: font(12);
                    font-family: var(--header-font);

                    .date {
                        position: relative;
                    }

                    margin-bottom: 4px;
                }

                .summary {
                    position: relative;
                    margin-bottom: 8px;
                }

                .description {
                    position: relative;
                    margin-left: 16px;
                    width: fit-content;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-items: flex-start;
                    align-content: flex-start;

                    .point {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        justify-items: flex-start;
                        justify-content: flex-start;
                        align-content: center;
                        align-items: center;
                        width: fit-content;
                        height: fit-content;
                        margin-bottom: 8px;
                        border-bottom: 2px dashed var(--header-bg);

                        &:last-of-type {
                            margin-bottom: 0;
                            border-bottom: none;
                        }

                        .bullet {
                            box-sizing: border-box;
                            width: font(16);
                            height: font(16);
                            border: solid var(--accent-color) font(8);
                            border-top: solid transparent font(8);
                            filter: drop-shadow(2px 2px 2px var(--font-color));
                            position: relative;
                            margin-right: 8px;
                            border-radius: 50%;
                            animation: rotate-bullet linear infinite 256s;
                        }

                        .text {
                            position: relative;
                        }
                    }
                }
            }
        }

        @keyframes rotate-bullet {
            0% {
                transform: rotate(0);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
</style>