<template>
    <div class="education-skills-container">
        <div class="education-skills-content">
            <span class="section-header" v-html="translate('education-header')"/>
            <div class="education-section">
                <div class="education-entry" v-for="(ed, ei) in education" :key="'education'+ei">
                    <span class="education-place" v-html="ed.place" v-if="ed.place!==''"/>
                    <a v-if="ed.link && ed.link !== ''" class="achievement-container" :href="ed.link" target="_blank">
                        <span class="achievement" v-html="ed.achievement + ':'" />
                        <span class="subject" v-html="ed.subject" />
                    </a>
                    <div class="achievement-container" v-else>
                        <span class="achievement" v-html="ed.achievement + ':'"/>
                        <span class="subject" v-html="ed.subject"/>
                    </div>
                    <div class="years-container">
                        <span v-html="ed['starting-year']"/>
                        <span v-if="ed['ending-year'] && ed['ending-year'] !== ''" style="margin: 0 8px;">-></span>
                        <span v-html="ed['ending-year']"/>
                    </div>
                    <span class="description" v-html="ed.description"/>
                    <div class="certifications-container" v-if="ed.certifications && ed.certifications.length > 0 && typeof ed.certifications !== 'string'">
                        <span class="certifications-header" v-html="translate('certifications-header')"/>
                        <div v-for="certification in ed.certifications" :key="certification.type">
                            <a v-if="certification.link && certification.link !== ''" class="certification" v-html="certification.type" :href="certification.link" target="_blank"/>
                            <span v-else class="certification" v-html="certification.type"/>
                        </div>
                    </div>
                </div>
            </div>
            <span class="section-header" v-html="translate('skills-header')"/>
            <div class="skills-container">
                <div class="skill-entry" v-for="(skill, s) in skills" :key="'skill'+s">
                    <span v-html="skill.skill"/>
                    <div class="skill-proficiency">
                        <div class="proficiency-point" v-for="point in parseInt(skill.proficiency)" :key="'skill'+s+'proficiency'+point"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {
                education: [],
                skills: []
            };
        },
        mounted: async function ()
        {
            await fetch('./assets/data/skills-education.json').then((response) => { return response.json(); }).then((data) => { this.education = data.education; this.skills = this.mergeSort(data.skills, false, "proficiency"); });
            this.dispatchAchievement("skill-set");
        },
    }
</script>
<style scoped lang="scss">
    .education-skills-container {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        font-size: font(16);

        .education-skills-content {
            position: relative;
            width: fit-content;
            height: fit-content;
            padding: 8px 10%;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            text-align: left;
            font-family: var(--body-font);
            color: var(--font-color);

            .section-header {
                position: relative;
                font-family: var(--header-font);
                font-size: font(24);
                font-weight: 700;
                text-transform: uppercase;
            }

            .education-section {
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;

                .description
                {
                    position: relative;
                }
                
                span {
                    position: relative;
                }

                a {
                    position: relative;

                    &:link, &:visited {
                        color: var(--font-color);
                    }

                    &:active, &:hover {
                        color: var(--header-bg);
                    }
                }

                .education-entry {
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-items: flex-start;
                    align-content: flex-start;
                    margin: 8px 0;
                    border-bottom: 2px solid var(--header-bg);

                    &:first-of-type {
                        margin-top: 0;
                    }

                    &:last-of-type {
                        border-bottom: none;
                    }

                    .education-place {
                        position: relative;
                        font-family: var(--header-font);
                        font-weight: 700;
                    }

                    .achievement-container {
                        position: relative;
                        width: fit-content;
                        height: fit-content;
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        justify-items: flex-start;
                        justify-content: flex-start;
                        align-items: flex-end;
                        align-content: flex-end;
                        flex-wrap: wrap;

                        .achievement {
                            position: relative;
                            font-weight: 700;
                        }

                        .subject {
                            position: relative;
                            margin-left: 8px;
                        }
                    }

                    .certifications-container {
                        position: relative;
                        width: fit-content;
                        height: fit-content;
                        flex-direction: column;
                        justify-items: flex-start;
                        justify-content: flex-start;
                        align-items: flex-start;
                        align-content: flex-start;

                        .certifications-header {
                            position: relative;
                            font-weight: bold;
                        }

                        .certification
                        {
                            position: relative;
                            margin-left: 8px;
                        }
                    }

                    .years-container {
                        position: relative;
                        width: fit-content;
                        height: fit-content;
                        display: flex;
                        flex-direction: row;
                        justify-items: flex-start;
                        justify-content: flex-start;
                        align-items: center;
                        align-content: center;
                        flex-wrap: wrap;
                        font-style: italic;

                        span {
                            position: relative;
                        }
                    }
                }
            }

            .skills-container {
                position: relative;
                display: flex;
                width: fit-content;
                height: fit-content;
                flex-direction: row;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;

                .skill-entry {
                    position: relative;
                    display: flex;
                    width: fit-content;
                    height: fit-content;
                    flex-direction: row;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-items: center;
                    align-content: center;
                    margin-right: 16px;

                    span {
                        position: relative;
                        margin-right: 4px;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }

                    .skill-proficiency {
                        position: relative;
                        display: flex;
                        width: fit-content;
                        height: fit-content;
                        flex-direction: row;
                        justify-items: flex-start;
                        justify-content: flex-start;
                        align-items: flex-end;
                        align-content: flex-end;

                        .proficiency-point {
                            position: relative;
                            width: font(4);
                            height: font(16);
                            background-color: var(--header-bg);
                            box-shadow: 2px 2px 2px var(--font-color);
                            margin: 0 2px;

                            &:first-of-type {
                                margin-left: 0;
                            }

                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>