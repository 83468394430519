<template>
    <div class="music-player-container">
        <audio id="anrsycst" loop preload="auto">
            <source src="../assets/audio/anrsycst.mp3" type="audio/mpeg">
        </audio>
        <div class="musical-implements">
            <div v-if="!isPlaying" class="play-button" @click="togglePlay()" v-on:keydown.enter="togglePlay()" tabindex="0" role="button">
                <div class="triangle" :class="{'disabled': playTimeout}"/>
            </div>
            <div v-if="isPlaying" class="play-button" @click="togglePlay()" v-on:keydown.enter="togglePlay()" tabindex="0" role="button">
                <div class="bar" :class="{'disabled': playTimeout}"/>
                <div class="bar" :class="{'disabled': playTimeout}"/>
            </div>
            <div class="song-display">
                <div class="song-view-window">
                    <span class="song-name" v-html="translate('song-title')"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'MusicPlayer',
        data: function () {
            return {
                isPlaying: false,
                playTimeout: null,
                audioElement: null
            };
        },
        methods: {
            togglePlay: function () {
                if (this.playTimeout) return;
                this.isPlaying = !this.isPlaying
                if (this.isPlaying) this.audioElement.play();
                else this.audioElement.pause();
                this.playTimeout = setTimeout(function () { this.playTimeout = null; }.bind(this), 500);
            }
        },
        mounted: async function () {
            this.audioElement = document.getElementById("anrsycst");
        }
    }
</script>
<style scoped lang="scss">
    .music-player-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        background-color: var(--header-bg);
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
        pointer-events: none;

        .musical-implements {
            position: relative;
            width: auto;
            height: fit-content;
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            justify-content: center;
            justify-items: center;
            align-items: center;
            align-content: center;
            pointer-events: none;
            padding: 16px 32px;

            .play-button {
                width: 64px;
                height: 64px;
                pointer-events: all;
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: center;
                justify-items: center;
                align-items: center;
                align-content: center;
                filter: drop-shadow(2px 2px 2px var(--font-color));
                transform: scale(1);

                &:hover {
                    transform: scale(1.1);
                }

                &:active {
                    transform: scale(0.99);
                    filter: none;
                }

                .triangle {
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    border-top: 32px solid transparent;
                    border-bottom: 32px solid transparent;
                    border-left: 64px solid var(--accent-color);

                    &.disabled {
                        border-left-color: var(--content-bg);
                    }

                    transition: background-color linear 0.25s;
                }

                .bar {
                    width: 16px;
                    margin: 0 8px;
                    height: 100%;
                    background-color: var(--accent-color);

                    &.disabled {
                        background-color: var(--content-bg);
                    }

                    transition: background-color linear 0.25s;
                }
            }

            .song-display {
                position: relative;
                width: auto;
                flex-grow: 1;
                height: 64px;
                margin-left: 16px;
                background-color: var(--content-bg);
                box-shadow: inset 4px 4px 8px var(--font-color), inset -4px -4px 8px var(--font-color);

                .song-view-window {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-items: flex-start;
                    align-content: flex-start;
                    overflow-x: clip;
                    overflow-y:clip;
                    pointer-events: none;
                    flex-wrap: nowrap;

                    .song-name
                    {
                        position: absolute;
                        top: 50%;
                        left: 100%;
                        width: fit-content;
                        height: fit-content;
                        white-space: nowrap;
                        transform: translate(0%, -50%);
                        font-size: font(24);
                        color: var(--font-color);

                        animation: song-scroll 32s linear infinite;
                    }

                    @keyframes song-scroll{
                        0% {
                            left: 100%;
                            transform: translate(0%, -50%);
                        }
                        100%
                        {
                            left: 0%;
                            transform: translate(-100%, -50%);
                        }
                    }
                }
            }
        }
    }
</style>