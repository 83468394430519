<template>
    <div class="organizations-container">
        <div class="organizations-content">
            <span class="section-header" v-html="translate('organizations-header')"/>
            <div class="group-container" v-for="(group, g) in organizationsData.groups" :key="'group'+g">
                <span class="group-header" v-html="group.name"/>
                <div class="organization-entry" v-for="(org, o) in group.organizations" :key="'group'+g+'org'+o">
                    <span class="organization-name" v-html="org.name"/>
                    <span class="organization-role" v-html="org.role"/>
                    <span class="organization-description" v-html="org.description"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {
                organizationsData: {}
            };
        },
        mounted: async function()
        {
            await fetch('./assets/data/organizations-affiliations.json').then((response) => { return response.json(); }).then((data) => { this.organizationsData = data; });
        }
    }
</script>
<style scoped lang="scss">
    .organizations-container {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        font-size: font(16);

        .organizations-content {
            position: relative;
            width: fit-content;
            height: fit-content;
            padding: 8px 10%;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            text-align: left;
            font-family: var(--body-font);
            color: var(--font-color);

            .section-header {
                position: relative;
                font-family: var(--header-font);
                font-size: font(24);
                font-weight: 700;
                text-transform: uppercase;
            }

            .group-container {
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
                margin-bottom: 8px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .group-header {
                    position: relative;
                    font-family: var(--header-font);
                    font-size: font(24);
                    font-weight: 700;
                    text-transform: uppercase;
                    pointer-events: all;
                }

                .organization-entry {
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    display: flex;
                    flex-direction: column;
                    justify-items: flex-start;
                    justify-content: flex-start;
                    align-items: flex-start;
                    align-content: flex-start;
                    margin: 8px 0;
                    border-bottom: 2px solid var(--header-bg);

                    &:first-of-type {
                        margin-top: 0;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                        border-bottom: none;
                    }

                    .organization-name {
                        position: relative;
                        font-family: var(--header-font);
                        font-weight: 700;
                    }
                    .organization-role
                    {
                        position: relative;
                        font-style: italic;
                    }
                    .organization-description
                    {
                        position: relative;
                    }
                }
            }
        }
        }
</style>