import { translateKey, translateKeyRaw } from "./translate.js"
const date = new Date();
export const mixins = {
    props: {},
    computed: {},
    methods:
    {
        translate: function(key) { return translateKey(key); },
        translateRaw: function(key) { return translateKeyRaw(key); },
        openURL: function(url) {
            window.open(url, "_blank");
        },
        clamp: function (number, min, max)
        {
            return Math.min(Math.max(number, min), max);
        },
        mergeSort: function (array, ascending = true, key = "") {
            if (array.length <= 1) return [...array];
            let input = [...array];
            let output = [];
            if (input.length === 2) {
                if (ascending) {
                    if (key !== "") {
                        if (input[0][key] < input[1][key]) {
                            output.push(input[0]);
                            output.push(input[1]);
                        }
                        else {
                            output.push(input[1]);
                            output.push(input[0]);
                        }
                    }
                    else {
                        if (input[0] < input[1]) {
                            output.push(input[0]);
                            output.push(input[1]);
                        }
                        else {
                            output.push(input[1]);
                            output.push(input[0]);
                        }
                    }
                }
                else {
                    if (key !== "") {
                        if (input[0][key] > input[1][key]) {
                            output.push(input[0]);
                            output.push(input[1]);
                        }
                        else {
                            output.push(input[1]);
                            output.push(input[0]);
                        }
                    }
                    else {
                        if (input[0] > input[1]) {
                            output.push(input[0]);
                            output.push(input[1]);
                        }
                        else {
                            output.push(input[1]);
                            output.push(input[0]);
                        }
                    }
                }
                return [...output];
            }
            let half = Math.floor(input.length / 2);
            let left = input.slice(0, half);
            let right = input.slice(half);
            if (left.length > 1) left = this.mergeSort(left, ascending, key);
            if (right.length > 1) right = this.mergeSort(right, ascending, key);
            let li = 0;
            let ri = 0;
            while (li < left.length || ri < right.length)
            {
                if (li === left.length) {
                    output.push(right[ri])
                    ri++;
                    continue;
                }
                if (ri === right.length)
                {
                    output.push(left[li])
                    li++;
                    continue;
                }
                if (ascending)
                {
                    if (key !== "")
                    {
                        if (left[li][key] < right[ri][key]) {
                            output.push(left[li]);
                            li++;
                            continue;
                        }
                        else
                        {
                            output.push(right[ri]);
                            ri++;
                            continue;
                        }
                    }
                    else
                    {
                        if (left[li] < right[ri])
                        {
                            output.push(left[li]);
                            li++;
                            continue;
                        }
                        else
                        {
                            output.push(right[ri]);
                            ri++;
                            continue;
                        }
                    }
                }
                if (key !== "")
                {
                    if (left[li][key] > right[ri][key])
                    {
                        output.push(left[li]);
                        li++;
                        continue;
                    }
                    else
                    {
                        output.push(right[ri]);
                        ri++;
                        continue;
                    }
                }
                else
                {
                    if (left[li] > right[ri])
                    {
                        output.push(left[li]);
                        li++;
                        continue;
                    }
                    else
                    {
                        output.push(right[ri]);
                        ri++;
                    }
                }
            }
            return [...output];
        },
        writeCookie: function (cookieName, cookieData, expiredays = 0)
        {
            document.cookie = cookieName + "=" + cookieData + ";" + (expiredays > 0 ? ("Expires=" + date.setTime(date.getTime() + (expiredays * 24 * 60 * 60 * 1000))) : "")+"Path=/;SameSite=Strict";
        },
        readCookie: function (cookieName)
        {
            let output = null;
            let name = cookieName + "=";
            let cookieDecode = decodeURIComponent(document.cookie);
            let cookieArray = cookieDecode.split(";");
            for (let i = 0; i < cookieArray.length; i++)
            {
                let c = cookieArray[i].trim();
                if (c.indexOf(name) === 0)
                {
                    return c.substring(name.length);
                }
            }
            return output;
        },

        dispatchAchievement: function (key, game = "website") {
            let event = new CustomEvent("website-achievement", {
                detail: {
                    game: game,
                    key: key
                }
            });

            document.dispatchEvent(event);
        }
    }
};