<template>
    <div class="achievements-container">
        <div class="achievements-content">
            <span class="achievements-header" v-html="translate('achievements-button')"/>
            <div class="achievements-category" v-for="game in Object.keys(achievementsData)" :key="game" :style="{'display': achievements[game] && achievements[game] !== null && achievements[game] !== undefined ? 'inline-block' : 'none'}">
                <span class="achievements-group" v-html="achievementsData[game].title"/>
                <div class="achievements-list">
                    <div class="achievement-entry" v-for="achievement in Object.keys(achievementsData[game].achievements)" :key="achievement" :style="{'display': achievementsData[game].achievements[achievement].hidden && achievementsData[game].achievements[achievement].hidden !== null && achievementsData[game].achievements[achievement].hidden !== undefined && achievements[game] && achievements[game] !== null && achievements[game] !== undefined && !achievements[game].achievements.includes(achievement) ? 'none' : 'inline-block', 'opacity': achievements[game] && achievements[game] !== null && achievements[game] !== undefined && achievements[game].achievements.includes(achievement) ? 1 : 0.25}">
                        <span class="achievement-title" v-html="achievementsData[game].achievements[achievement].title"/>
                        <span class="achievement-quote" v-html='"\""+achievementsData[game].achievements[achievement].quote+"\""' :style="{'display': achievements[game] && achievements[game] !== null && achievements[game] !== undefined && !achievements[game].achievements.includes(achievement) ? 'none': 'inline-block'}"/>
                        <span class="achievement-description" v-html="achievementsData[game].achievements[achievement].description"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        data: function () {
            return {
                achievementsData: {}
            };
        },
        mounted: async function () {
            await fetch('./assets/data/achievements.json').then((response) => { return response.json(); }).then((data) => { this.achievementsData = data; });
            this.dispatchAchievement("achievements");
        },
        computed: {
            ...mapGetters({
                achievements: "getAcquiredAchievements"
            })
        }
    }
</script>
<style scoped lang="scss">
    .achievements-container {
        position: relative;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;

        .achievements-content {
            position: relative;
            width: fit-content;
            height: fit-content;
            padding: 8px 10%;
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            text-align: left;
            font-family: var(--body-font);
            color: var(--font-color);

            .achievements-category {
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
                margin-bottom: 8px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .achievements-group {
                position: relative;
                font-family: var(--header-font);
                font-size: font(24);
                font-weight: 700;
            }

            .achievements-header {
                position: relative;
                font-family: var(--header-font);
                font-size: font(24);
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom:16px;
            }

            .achievements-list {
                margin-left: 16px;
                position: relative;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
            }

            .achievement-entry {
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                justify-items: flex-start;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
                margin: 8px 0;
                font-size: font(16);

                &:first-of-type {
                    margin-top: 0;
                }

                &:last-of-type {
                    margin-bottom: 0;
                    border-bottom: none;
                }

                .achievement-title {
                    position: relative;
                    width: fit-content;
                    height: fit-content;
                    font-family: var(--header-font);
                    font-weight: 700;
                }

                .achievement-quote {
                    position: relative;
                    font-style: italic;
                    margin-left:8px;
                }

                .achievement-description {
                    position: relative;
                    margin-bottom: 8px;
                    margin-left:8px;
                }
            }
        }
    }
</style>